<template>
    <section class="hero is-fullheight" data-theme="light">
        <div class="hero-header has-background-dark">
            <AppHeader
                v-if="useAuthStore().isAuthenticated && !useAuthStore().first_login && router.path != '/login'" />

        </div>
        <div class=" hero-body">
            <Sidebar v-if="useAuthStore().isAuthenticated && !useAuthStore().first_login && router.path != '/login'" />
            <div class=" container is-fluid">
                <slot />
            </div>

            <KeepAlive>
                <DropdownProgressoDisparo
                    v-show="!wppDisparoStore.concluido && wppDisparoStore.disparo.id && useAuthStore().isAuthenticated && router.path != '/disparo'" />
            </KeepAlive>

        </div>

    </section>




</template>

<script setup>

const wppDisparoStore = useWppDisparoStore()
const router = useRoute()


</script>

<style lang="scss" scoped>
.hero {
    background-image: url('~/assets/images/background.webp');
    overflow-y: auto;
    background-repeat: initial;
    background-position: 50%;
    background-size: cover;
}



.hero-body::-webkit-scrollbar {
    width: 6px;
    background-color: transparent;
}

.hero-body::-webkit-scrollbar-track {
    background-color: transparent;
    display: none;
}

.hero-body::-webkit-scrollbar-thumb {
    background: #6a6a6a;
    border-radius: 2px;

}

.hero-header {
    z-index: 35;
    position: fixed;
    width: 100%;
}

.hero-body {
    position: relative;
    padding-top: 5.5rem;
    align-items: center;
    overflow-y: auto;
    margin-top: 3dvh;
    max-height: 100dvh;
}

.container {
    height: 100%;
    width: 100%;

}

@media (max-width: 896px) {


    .hero-body:has(#main) {
        align-items: flex-start !important;
    }

    .container {
        padding: 1.5rem;
        padding-left: 1.2rem;
        padding-right: 1.2rem;
    }

    #dropdown-disparo {
        position: absolute;
        bottom: 15px;
        right: 50%;
        width: 90dvw;
        transform: translateX(50%);
    }
}

@media screen and (min-width: 1024px) {

    .container {
        margin-left: 3dvw;
    }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {

    .hero-body {

        align-items: center !important;
        justify-content: center !important;

    }

    .container {
        height: 100%;
    }

}


#dropdown-disparo {
    position: fixed;
    bottom: 15px;
    right: 15px;
    z-index: 1000;
}

.hero-foot {
    height: 20dvh;
    display: flex;
    align-items: flex-end;

}

.footer {
    background-color: hsla(221deg, 14%, 21%, 0.8);
    width: 100%;
}
</style>