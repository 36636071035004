<template>
    <aside class="menu is-hidden-touch" :class="{ 'is-colapse': sidebarColapse }" @mouseenter="handleHover"
        @mouseleave="handleHover" @click="handleClick">
        <ul class="menu-list mt-5">
            <li :class="{ 'is-active': route.path == '/campanhas' || route.path.includes('/campanha') }">
                <NuxtLink class="is-ghost navbar-item" :to="`/campanhas`" aria-label="Ir para Minhas Campanhas">
                    <Icon name="mdi:cards-variant" color="white" />
                    <p class="has-text-white" v-show="sidebarColapse == true">Minhas Campanhas</p>
                </NuxtLink>
            </li>
            <li :class="{ 'is-active': route.path == '/disparo' }">
                <NuxtLink class="is-ghost navbar-item" :to="`/disparo`" aria-label="Ir para Disparar Campanha">
                    <Icon name="mdi:send" color="white" />
                    <p class="has-text-white" v-show="sidebarColapse == true">Disparar Campanha</p>
                </NuxtLink>
            </li>
            <li :class="{ 'is-active': route.path == '/agendamentos' }">
                <NuxtLink class="is-ghost navbar-item" :to="`/agendamentos`" aria-label="Ir para Disparos Agendados">
                    <Icon name="mdi:calendar-clock" color="white" />
                    <p class="has-text-white" v-show="sidebarColapse == true">Agendamentos</p>
                </NuxtLink>
            </li>
            <li :class="{ 'is-active': route.path == '/coletar' }">
                <NuxtLink class="is-ghost navbar-item" :to="`/coletar`" aria-label="Ir para Coletar Grupos">
                    <Icon name="mdi:account-group" color="white" />
                    <p class="has-text-white" v-show="sidebarColapse == true">Coletar Grupos</p>
                </NuxtLink>
            </li>
            <li :class="{ 'is-active': route.path == '/disparos' }">
                <NuxtLink class="is-ghost navbar-item" :to="`/disparos`" aria-label="Ir para Histórico de Disparos">
                    <Icon name="mdi:history" color="white" />
                    <p class="has-text-white" v-show="sidebarColapse == true">Histórico de Disparos</p>
                </NuxtLink>
            </li>
        </ul>

        <ul class="menu-list bottom">
            <li :class="{ 'is-active': route.path == '/preferencias' }">
                <NuxtLink class="is-ghost navbar-item" :to="`/preferencias`" aria-label="Ir para Preferências">
                    <Icon name="mdi:tools" color="white" />
                    <p class="has-text-white" v-show="sidebarColapse == true">Preferências</p>
                </NuxtLink>
            </li>
            <li :class="{ 'is-active': route.path == '/ajuda' }">
                <NuxtLink class="is-ghost navbar-item" :to="`/ajuda`" aria-label="Ir para Ajuda & Suporte">
                    <Icon name="mdi:help-circle" color="white" />
                    <p class="has-text-white" v-show="sidebarColapse == true">Ajuda & Suporte</p>
                </NuxtLink>
            </li>
        </ul>
    </aside>

</template>

<script setup>

const sidebarColapse = ref(false)
const { $viewport } = useNuxtApp()

const route = useRoute()


function handleClick(event) {

    console.log(route.path)
    // const target = event.target.closest('li')
    // if (target) {
    //     const oldTarget = document.querySelector('.is-active')
    //     if (oldTarget) {
    //         oldTarget.classList.remove('is-active')
    //     }

    //     target.classList.add('is-active')
    // }
}

function handleHover() {
    sidebarColapse.value = !sidebarColapse.value
}

</script>

<style lang="scss" scoped>
.menu {
    background-color: #3e434e;
    border-right: 2px solid #2f2f2f;
    height: 100%;
    width: 56px;
    position: fixed;
    top: 0dvh;
    left: 0;
    display: grid;
    justify-content: center;
    z-index: 31;
    padding-top: 2.5rem;
}

@media screen and (max-width: 932px) {
    .menu {
        display: none;
        position: absolute !important;
    }

    .menu.is-colapse {
        display: grid;
    }
}


.menu.is-colapse {
    width: 228px;
    padding-left: 4px;
    padding-right: 4px;
}

.menu-list {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    width: 100%;
}

.menu-list a {
    display: flex;
    width: 100%;
    gap: 10px;
    align-items: flex-start;
    justify-content: flex-start;
    text-wrap: nowrap;
}

.menu-list li {
    width: 100%;

    &.is-active {
        //border-left: 1px solid #5e5e5e;
        background-color: #2E333D;
        border-radius: 2px;
    }
}

.menu-list li:hover {
    background-color: #2f2f2f;
}

.menu-list.bottom {
    justify-self: flex-end;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    margin-bottom: 20px;
}
</style>