<template>
    <div class="dropdown is-left is-up" :class="{ 'is-active': ativo }" id="dropdown-disparo">
        <div class="dropdown-trigger">
            <button type="button" class="button trigger is-medium is-responsive is-rounded sending"
                :class="{ 'is-warning ': wppDisparoStore.disparando, 'is-success': wppDisparoStore.disparado, 'is-danger': wppDisparoStore.erro }"
                @click="trigger" :progresso="wppDisparoStore.progresso">
                {{ wppDisparoStore.progresso }}%
            </button>

        </div>
        <div class="dropdown-menu pb-0" id="dropdown-menu2" role="menu">
            <button type="button" class="button is-dark header is-medium is-fullwidth"
                :class="{ 'has-text-warning': wppDisparoStore.disparando, 'has-text-success': wppDisparoStore.disparado, 'has-text-danger': wppDisparoStore.erro }"
                @click="trigger">
                <span>
                    {{ wppDisparoStore.disparo.estado }}
                </span>
                <span class="icon">
                    <Icon name="formkit:minimize" />
                </span>
            </button>
            <div class="dropdown-content p-3">
                <div class="columns is-multiline m-0">
                    <div class="column is-12 px-1">
                        <progress class="progress"
                            :class="{ 'is-warning': wppDisparoStore.disparando, 'is-success': wppDisparoStore.disparado, 'is-danger': wppDisparoStore.erro }"
                            :value="wppDisparoStore.progresso" max="100" id="progress-bar">
                        </progress>
                    </div>
                    <div class="column is-12 px-0">
                        <div id="info">
                            <div>
                                <p class="has-text-weight-bold">Campanha: </p>
                                <p class="subtitle">{{ wppDisparoStore.disparo.campanha }}</p>
                            </div>

                            <div>
                                <p class="has-text-weight-bold">Total:</p>
                                <p class="subtitle">{{ wppDisparoStore.disparo.quantidade_total }}</p>
                            </div>
                            <div>
                                <p class="has-text-weight-bold">Disparados:</p>
                                <p class="subtitle">{{ wppDisparoStore.quantidadeDisparada }}</p>
                            </div>

                            <div>
                                <p class="has-text-weight-bold">Válidos:</p>
                                <p class="subtitle has-text-success">{{ wppDisparoStore.quantidadeValida }}</p>
                            </div>

                            <div>
                                <p class="has-text-weight-bold">Inválidos:</p>
                                <p class="subtitle has-text-danger">{{ wppDisparoStore.quantidadeInvalida }}</p>
                            </div>
                        </div>
                    </div>
                    <div class="column is-12 px-2">
                        <hr class="mt-0">
                        <button class="button is-danger is-fullwidth" @click="wppDisparoStore.cancelarDisparo()"
                            v-if="wppDisparoStore.disparando">
                            Cancelar
                        </button>

                        <button class="button is-dark is-fullwidth" @click="wppDisparoStore.concluirDisparo()"
                            v-else-if="wppDisparoStore.disparado">
                            Concluir
                        </button>

                        <button class="button is-dark  is-fullwidth" @click="wppDisparoStore.reset()"
                            v-else-if="wppDisparoStore.cancelado || wppDisparoStore.erro">
                            Finalizar
                        </button>
                    </div>
                </div>


            </div>
        </div>
    </div>
</template>

<script setup>
let ativo = ref(false)
const wppDisparoStore = useWppDisparoStore()


function trigger() {
    ativo.value = !ativo.value
}

</script>

<style lang="scss" scoped>
.trigger {
    border-bottom: none;
    aspect-ratio: 1;

}

.trigger::before {
    content: '';
    position: absolute;
    width: 90%;
    height: 90%;
    border: 2px rgb(0, 0, 0) solid;
    background-color: transparent;
    border-radius: inherit;
}

.sending::before {
    border: 2px rgb(0, 0, 0) dashed;
    -webkit-animation: spin 5s linear infinite;
    -moz-animation: spin 5s linear infinite;
    animation: spin 5s linear infinite;
}

@-moz-keyframes spin {
    100% {
        -moz-transform: rotate(360deg);
    }
}

@-webkit-keyframes spin {
    100% {
        -webkit-transform: rotate(360deg);
    }
}

@keyframes spin {
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}


#info {
    display: flex;
    flex-direction: column;
    gap: 10px;


    & div {
        display: flex;
        padding-left: 10px;
        padding-right: 10px;
        justify-content: space-between;
    }

}


.header {
    border-radius: 5px 5px 0 0;
}

.dropdown-menu {
    position: absolute;
    margin-bottom: 20px;
    min-width: 300px;
    transform: translateX(-75%);
}

.dropdown-content {
    width: 100%;
    padding: 10px;
}

.columns {
    width: auto;
}

#progress-bar {
    border-radius: 2px;
    background-color: aliceblue;
    position: relative;
    height: 2rem;
}

#progress-bar::after {
    content: attr(value) '%';
    color: rgb(0, 0, 0);
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.dropdown-content {
    margin-bottom: 0;
    border-radius: 0px 0px 5px 5px;
    position: relative;
    border-bottom: none;
}

@media (max-width: 768px) {

    .dropdown-trigger,
    .dropdown-content,
    .dropdown-menu {
        width: 100%;
    }

    .dropdown-menu {
        transform: translateX(0);
    }

    .dropdown-trigger {
        display: flex;
        justify-content: flex-end;
    }

    .dropdown-content {
        max-width: 100%;
        width: auto;
    }

    .trigger {
        //border: 1px gray solid;
        height: 8dvh;
        border-bottom: none;
    }
}
</style>