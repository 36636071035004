export default function (data: string) {

  if (!data) {
    return data;
  }

  // Remove todos os caracteres que não sejam dígitos
  const telefoneLimpo = data.replace(/\D/g, '');

  // Se o número começa com 55 (código do Brasil), removemos os dois primeiros dígitos
  const telefoneSemDDI = telefoneLimpo.startsWith('55') ? telefoneLimpo.slice(2) : telefoneLimpo;

  // Verifica se é um número de celular (11 dígitos) ou fixo (10 dígitos)
  if (telefoneSemDDI.length === 11) {
    // Formato para celular: (XX) XXXXX-XXXX
    return telefoneSemDDI.replace(/(\d{2})(\d{5})(\d{4})/, '($1) $2-$3');
  } else if (telefoneSemDDI.length === 10) {
    // Formato para telefone fixo: (XX) XXXX-XXXX
    return telefoneSemDDI.replace(/(\d{2})(\d{4})(\d{4})/, '($1) $2-$3');
  } else {
    // Retorna o número original se não tiver o formato esperado
    return data;
  }
}
